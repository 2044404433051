var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, Step, StepButton, StepContent, StepLabel, Stepper, } from '@mui/material';
import { logError } from '@one-vision/utils';
import { useCallback, useState } from 'react';
import { ModalIDs, WizardStep } from '../../../const';
import { DzProjectStage, } from '../../../types';
import { DzStepIcon } from '../../icons';
import { useDrawerStyles, useModalManager, usePartnerConfig, withModalManager, } from '../../shared';
import { useStyles } from './dz-create-project-dialog.styles';
import DzMembershipInfoStep from './dz-membership-info-step';
import DzOrganizationStep from './dz-organization-step';
import DzPrimaryUserStep from './dz-primary-user-step';
import DzProjectStep from './dz-project-step';
export const DzCreateProjectDialog = ({ isOpen, close, clear, apiClient, actions, dispatch, products, owners, preselectedAddress, preselectedClient, preselectedStep, thunks, labels, allClients, zendesk, }) => {
    const classes = useStyles();
    const dialogClasses = useDrawerStyles();
    const { openModal } = useModalManager();
    const [activeStep, setActiveStep] = useState(preselectedStep || WizardStep.Organization);
    const [isLoading, setLoadingState] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(preselectedAddress || null);
    const [selectedClient, setSelectedClient] = useState(preselectedClient || null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [willReceiveEmails, setEmailOption] = useState(true);
    const [isPreSell, setIsPreSell] = useState(false);
    const partnerConfig = usePartnerConfig();
    const preSellHandler = useCallback(() => {
        setIsPreSell((prev) => !prev);
    }, [setIsPreSell]);
    const handleAddressChange = useCallback(async (address) => {
        setSelectedAddress(address);
        if (address && address.primaryClientId) {
            setActiveStep(2);
            const response = await apiClient.getClientsWithContacts({
                ovcid: address.primaryClientId,
            });
            if (response.data.length) {
                const [primaryClient] = response.data;
                setSelectedClient(primaryClient);
            }
        }
    }, [setSelectedClient, setSelectedAddress, setActiveStep]);
    const postToTray = useCallback(async () => {
        if (!selectedProject || !selectedAddress || !selectedClient) {
            return;
        }
        try {
            setLoadingState(true);
            if (activeStep === WizardStep.MembershipInformation)
                setIsSubmitting(true);
            await apiClient.postToTray({
                OVAID: selectedAddress.ovaid,
                OVCID: selectedClient.ovcid,
                OVPRJID: selectedProject === null || selectedProject === void 0 ? void 0 : selectedProject.ovprjid,
                Action: 'create_project',
                education_email: willReceiveEmails,
            }, 'createProject');
            const { phones, emails } = selectedClient, newClient = __rest(selectedClient, ["phones", "emails"]);
            close({
                newAddress: selectedAddress.primaryProjectId === selectedProject.ovprjid
                    ? selectedAddress
                    : null,
                projectStageId: selectedProject.projectStageId,
                newProject: selectedProject,
                newClient,
                tosAccepted: selectedAddress.tosAccepted,
            });
        }
        catch (error) {
            logError(error);
            setIsSubmitting(false);
            dispatch(actions.updateSnackbar({
                type: 'error',
                text: 'Oops! Something went wrong...',
            }));
        }
        finally {
            setLoadingState(false);
        }
    }, [
        selectedProject,
        selectedAddress,
        selectedClient,
        setLoadingState,
        willReceiveEmails,
        close,
        dispatch,
    ]);
    const handleNext = useCallback(async () => {
        if (activeStep < WizardStep.MembershipInformation) {
            return setActiveStep((previous) => previous + 1);
        }
        postToTray();
    }, [activeStep, setActiveStep, postToTray]);
    const handleBack = useCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }, [setActiveStep]);
    const linkAddressAndClient = useCallback(async () => {
        if (!selectedAddress || !selectedClient) {
            return;
        }
        setLoadingState(true);
        const { primaryClientEmailId, primaryClientPhoneNumberId, emails, phones = [], } = selectedClient;
        const email = primaryClientEmailId
            ? emails.find((email) => email.clientEmailId === primaryClientEmailId)
            : emails[0];
        const phone = primaryClientPhoneNumberId
            ? phones.find((phone) => phone.clientPhoneNumberId === primaryClientPhoneNumberId)
            : phones[0];
        const payload = {
            ovcid: selectedClient.ovcid,
            ovaid: selectedAddress.ovaid,
            email: email === null || email === void 0 ? void 0 : email.email,
            phone: phone === null || phone === void 0 ? void 0 : phone.phone,
        };
        if (!phone) {
            delete payload.phone;
        }
        try {
            await apiClient.updateClient(payload);
            setSelectedAddress(Object.assign(Object.assign({}, selectedAddress), { primaryClientId: selectedAddress.primaryClientId || selectedClient.ovcid }));
            handleNext();
        }
        catch (error) {
            logError(error);
            dispatch(actions.updateSnackbar({
                type: 'error',
                text: 'Oops! Something went wrong...',
            }));
        }
        finally {
            setLoadingState(false);
        }
    }, [
        handleNext,
        selectedClient,
        selectedAddress,
        setLoadingState,
        setSelectedAddress,
    ]);
    const createProject = useCallback(async (_a) => {
        var { sendEducationEmail } = _a, values = __rest(_a, ["sendEducationEmail"]);
        if (!selectedAddress || !selectedClient) {
            return;
        }
        setEmailOption(sendEducationEmail);
        try {
            setLoadingState(true);
            const apiCalls = [
                apiClient.postProject(Object.assign(Object.assign({}, values), { sendEducationEmail, ovaid: selectedAddress.ovaid })),
            ];
            if (selectedAddress.tosAccepted !== values.tosAccepted) {
                apiCalls.push(apiClient.updateAddress(selectedAddress.ovaid, {
                    tosAccepted: values.tosAccepted,
                }));
            }
            const result = await Promise.all(apiCalls);
            const [project] = result[0].data;
            if (result[1]) {
                setSelectedAddress(Object.assign(Object.assign({}, selectedAddress), { tosAccepted: 'Yes' }));
            }
            setSelectedProject(project);
            if (!selectedAddress.primaryProjectId) {
                setSelectedAddress(Object.assign(Object.assign({}, selectedAddress), { primaryProjectId: project.ovprjid }));
            }
            handleNext();
        }
        catch (error) {
            logError(error);
            dispatch(actions.updateSnackbar({
                type: 'error',
                text: 'Oops! Something went wrong...',
            }));
        }
        finally {
            setLoadingState(false);
        }
    }, [
        selectedAddress,
        setSelectedAddress,
        setLoadingState,
        selectedClient,
        setSelectedProject,
        setEmailOption,
        handleNext,
        dispatch,
    ]);
    const deleteProject = useCallback(async () => {
        if (!selectedProject) {
            return;
        }
        const confirmation = await openModal(ModalIDs.confirmation, undefined);
        if (!confirmation) {
            return;
        }
        const { ovprjid, ovaid } = selectedProject;
        try {
            setLoadingState(true);
            await apiClient.deleteProject({ ovprjid, ovaid });
            setSelectedProject(null);
            if ((selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.primaryProjectId) === ovprjid) {
                setSelectedAddress(Object.assign(Object.assign({}, selectedAddress), { primaryProjectId: null }));
            }
        }
        catch (error) {
            logError(error);
            dispatch(actions.updateSnackbar({
                type: 'error',
                text: 'Oops! Something went wrong...',
            }));
        }
        finally {
            setLoadingState(false);
        }
    }, [
        selectedProject,
        setSelectedProject,
        selectedAddress,
        setSelectedAddress,
        openModal,
        setLoadingState,
    ]);
    const patchAddress = useCallback(async (values) => {
        if (!selectedAddress) {
            return;
        }
        try {
            setLoadingState(true);
            const result = await apiClient.updateAddress(selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.ovaid, values);
            const { data: [projectData], } = result;
            setSelectedAddress((previous) => (Object.assign(Object.assign({}, previous), projectData)));
            if (isPreSell && partnerConfig.accessToPreSoldMemberships) {
                await openModal(ModalIDs.preSoldMembership, {
                    address: Object.assign(Object.assign({}, selectedAddress), projectData),
                    zendesk,
                    annual: false,
                    dispatch,
                    actions,
                    scheduleImmediately: (selectedProject === null || selectedProject === void 0 ? void 0 : selectedProject.projectStageId) ===
                        DzProjectStage.ServiceClient,
                });
            }
            handleNext();
        }
        catch (error) {
            logError(error);
            dispatch(actions.updateSnackbar({
                type: 'error',
                text: 'Oops! Something went wrong...',
            }));
        }
        finally {
            setLoadingState(false);
        }
    }, [
        selectedAddress,
        setSelectedAddress,
        dispatch,
        setLoadingState,
        handleNext,
        isPreSell,
        partnerConfig.accessToPreSoldMemberships,
        zendesk,
    ]);
    const goToOrganization = useCallback(() => setActiveStep(WizardStep.Organization), [setActiveStep]);
    const goToPrimaryUser = useCallback(() => setActiveStep(WizardStep.AddPrimaryContact), [setActiveStep]);
    const goToProject = useCallback(() => setActiveStep(WizardStep.ProjectDetails), [setActiveStep]);
    const goToMembership = useCallback(() => setActiveStep(WizardStep.MembershipInformation), [setActiveStep]);
    const handleClose = useCallback(() => {
        close();
    }, [close]);
    return (_jsxs(Dialog, Object.assign({ open: isOpen, onClose: handleClose, closeAfterTransition: true, transitionDuration: 400, TransitionComponent: Slide, TransitionProps: {
            direction: 'left',
            onExited: clear,
            mountOnEnter: true,
            unmountOnExit: true,
        }, classes: {
            paper: dialogClasses.paper,
            container: dialogClasses.container,
        }, disableRestoreFocus: true }, { children: [_jsxs(DialogTitle, Object.assign({ className: dialogClasses.header }, { children: ["Create Project", _jsx(IconButton, Object.assign({ onClick: handleClose, color: "inherit" }, { children: _jsx(CloseIcon, { color: "inherit" }) }))] })), _jsxs(DialogContent, Object.assign({ className: dialogClasses.content }, { children: [isLoading && (_jsx(Box, Object.assign({ className: dialogClasses.overlay }, { children: _jsx(CircularProgress, {}) }))), _jsxs(Stepper, Object.assign({ activeStep: activeStep, orientation: "vertical", classes: {
                            root: classes.stepperRoot,
                        } }, { children: [_jsxs(Step, { children: [_jsx(StepButton, Object.assign({ onClick: goToOrganization }, { children: _jsx(StepLabel, Object.assign({ StepIconComponent: DzStepIcon }, { children: "Add Organization" })) })), _jsx(StepContent, { children: _jsx(DzOrganizationStep, { apiClient: apiClient, actions: actions, dispatch: dispatch, owners: owners, selectedAddress: selectedAddress, setSelectedAddress: handleAddressChange, handleNext: handleNext }) })] }), _jsxs(Step, { children: [_jsx(StepButton, Object.assign({ onClick: goToPrimaryUser }, { children: _jsx(StepLabel, Object.assign({ StepIconComponent: DzStepIcon }, { children: "Add Primary User" })) })), _jsx(StepContent, { children: _jsx(DzPrimaryUserStep, { apiClient: apiClient, actions: actions, dispatch: dispatch, immutable: Boolean(selectedAddress &&
                                                selectedClient &&
                                                selectedAddress.primaryClientId ===
                                                    selectedClient.ovcid), selectedClient: selectedClient, setSelectedClient: setSelectedClient, handleNext: linkAddressAndClient, handleBack: handleBack, thunks: thunks, labels: labels, allClients: allClients }) })] }), _jsxs(Step, { children: [_jsx(StepButton, Object.assign({ onClick: goToProject }, { children: _jsx(StepLabel, Object.assign({ StepIconComponent: DzStepIcon }, { children: "Add Project Details" })) })), _jsx(StepContent, { children: _jsx(DzProjectStep, { owners: owners, handleNext: handleNext, handleBack: handleBack, createProject: createProject, isPrimary: !(selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.primaryProjectId), tosAccepted: selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.tosAccepted, selectedProject: selectedProject, deleteProject: deleteProject, zendesk: zendesk }) })] }), _jsxs(Step, { children: [_jsx(StepButton, Object.assign({ onClick: goToMembership }, { children: _jsx(StepLabel, Object.assign({ StepIconComponent: DzStepIcon }, { children: "Membership Information" })) })), _jsx(StepContent, { children: _jsx(DzMembershipInfoStep, { handleBack: handleBack, products: products, selectedAddress: selectedAddress, patchAddress: patchAddress, selectedProject: selectedProject, isPreSell: isPreSell, onSwitchChange: preSellHandler, zendesk: zendesk }) })] })] }))] })), _jsxs(DialogActions, Object.assign({ classes: {
                    root: classes.actions,
                } }, { children: [_jsx(Button, Object.assign({ size: "large", variant: "text", color: "primary", onClick: handleClose }, { children: "Cancel" })), _jsx(Button, Object.assign({ size: "large", variant: "contained", color: "primary", type: "submit", form: "membership-form", disabled: activeStep !== WizardStep.MembershipInformation || isSubmitting }, { children: isSubmitting ? 'Submitting...' : 'Submit' }))] }))] })));
};
export default withModalManager()(DzCreateProjectDialog);
