import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import { Box, ClickAwayListener, ListItemIcon, TextField, List, ListItem, ListItemText, ListSubheader, } from '@mui/material';
import CheckIcon from '@mui/icons-material/CheckCircle';
import { csn, logRender } from '@one-vision/utils';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { useStyles } from './dz-search-or-create-list.styles';
import { keyboardEventHelper, useGlobalStyles, usePersistent, } from '../../..';
const InternalListItem = ({ isSelected, isSelectedRow, item, onSelect, onHideDropDown }) => {
    const classes = useStyles();
    const handleClick = useCallback(() => {
        onHideDropDown();
        onSelect(item.id || '');
    }, [onHideDropDown, onSelect, item.id]);
    const ref = useRef(null);
    if (isSelectedRow && ref.current) {
        ref.current.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
        });
    }
    return (_jsxs(ListItem, Object.assign({ className: csn([classes.selectedRow, isSelectedRow]), ref: ref, button: true, tabIndex: -1, onClick: handleClick }, { children: [_jsx(ListItemText, { primary: item.text }), isSelected ? (_jsx(ListItemIcon, Object.assign({ className: classes.listItemIcon }, { children: _jsx(CheckIcon, {}) }))) : null] })));
};
const InternalListItemWithMemo = React.memo(InternalListItem);
export const DzSearchOrCreateListView = ({ createSectionText, createSectionIsOn, selectSectionText, label, items, selectedId, inputText, error, errorText, disabled: disables, onCreate, onSelect, onValueChange, }) => {
    logRender(DzSearchOrCreateListView);
    const classes = Object.assign(Object.assign({}, useGlobalStyles()), useStyles());
    const [textForFilter, setTextForFilter] = useState('');
    const [showDropDown, setShowDropDown] = useState(false);
    const [selectedRow, setSelectedRow] = useState(-1);
    const ref = useRef(null);
    const inputSubject$ = usePersistent(() => new Subject());
    const dropDownSubject$ = usePersistent(() => new Subject());
    const handleChange = useCallback((event) => {
        const newValue = event.currentTarget.value;
        onValueChange(newValue);
        inputSubject$.next(newValue);
    }, [inputSubject$, onValueChange]);
    const handleHideDropdown = useCallback(() => setShowDropDown(false), [setShowDropDown]);
    const showDropDownHandler = useCallback(() => setShowDropDown(true), [setShowDropDown]);
    const onKeyDownHandler = useCallback((event) => {
        var _a;
        switch (keyboardEventHelper.getKey(event)) {
            case keyboardEventHelper.keys.Escape:
                setShowDropDown(false);
                event.stopPropagation();
                break;
            case keyboardEventHelper.keys.Tab:
                setShowDropDown(false);
                break;
            case keyboardEventHelper.keys.ArrowDown: {
                const count = filteredItems.length - 1 + (showCreateSection ? 1 : 0);
                if (selectedRow + 1 <= count) {
                    setSelectedRow(selectedRow + 1);
                }
                setShowDropDown(true);
                break;
            }
            case keyboardEventHelper.keys.ArrowUp:
                if (selectedRow - 1 >= 0) {
                    setSelectedRow(selectedRow - 1);
                }
                setShowDropDown(true);
                break;
            case keyboardEventHelper.keys.Enter: {
                const itemCount = filteredItems.length + (showCreateSection ? 1 : 0);
                if (selectedRow === 0 && onCreate && showCreateSection) {
                    onCreate(inputText);
                    setShowDropDown(false);
                }
                else if (selectedRow >= 0 && itemCount) {
                    onSelect(((_a = filteredItems[selectedRow - (showCreateSection ? 1 : 0)]) === null || _a === void 0 ? void 0 : _a.id) || '');
                    setShowDropDown(false);
                }
                break;
            }
            default:
                setShowDropDown(true);
                break;
        }
    }, [setShowDropDown]);
    useEffect(() => {
        const inputSub = inputSubject$
            .pipe(debounceTime(200))
            .subscribe((value) => setTextForFilter(value));
        const dropDownSub = dropDownSubject$
            .pipe(debounceTime(150))
            .subscribe((value) => setShowDropDown(value));
        return () => {
            inputSub.unsubscribe();
            dropDownSub.unsubscribe();
        };
    }, [inputSubject$, dropDownSubject$]);
    useEffect(() => {
        inputSubject$.next(inputText);
        setSelectedRow(-1);
    }, [inputSubject$, inputText, setSelectedRow]);
    const filteredItems = useMemo(() => {
        if (textForFilter.length < 1) {
            return [];
        }
        const result = items.filter((item) => {
            const tokens = textForFilter.split(' ');
            if (tokens.length) {
                const values = Object.values(item.props);
                return tokens.reduce((result, token) => {
                    if (result) {
                        return Boolean(values.find((value) => value === null || value === void 0 ? void 0 : value.toString().toLowerCase().includes(token.toLowerCase())));
                    }
                    return result;
                }, true);
            }
            return false;
        });
        return result;
    }, [textForFilter, items]);
    const showCreateSection = inputText.trim().length || (!filteredItems.length && items.length);
    const filteredListItems = useMemo(() => {
        return filteredItems.length ? (_jsxs(_Fragment, { children: [_jsx(ListSubheader, Object.assign({ className: classes.listHeader, disableSticky: true, component: "div" }, { children: selectSectionText.toUpperCase() })), filteredItems.map((listItem, index) => {
                    const isSelectedRow = selectedRow === index + (showCreateSection ? 1 : 0);
                    return (_jsx(InternalListItemWithMemo, { isSelectedRow: isSelectedRow, isSelected: selectedId === listItem.id, item: listItem, onHideDropDown: handleHideDropdown, onSelect: onSelect }, listItem.id));
                })] })) : null;
    }, [
        filteredItems,
        selectedId,
        selectedRow,
        classes.listHeader,
        handleHideDropdown,
        onSelect,
        selectSectionText,
        showCreateSection,
    ]);
    return (_jsx(ClickAwayListener, Object.assign({ onClickAway: () => {
            setShowDropDown(false);
        } }, { children: _jsxs("div", Object.assign({ className: csn(classes.root, classes.gcFieldMargin) }, { children: [_jsx(TextField, { ref: ref, onFocus: showDropDownHandler, label: label, disabled: disables, 
                    // this is needed to remove browser's autocomplete
                    autoComplete: "new-password", value: inputText, onChange: handleChange, error: Boolean(error), helperText: errorText, onKeyDown: onKeyDownHandler, inputProps: {
                        'data-testid': 'input-email',
                    } }), _jsx(Box, Object.assign({ className: classes.dropDownRoot, hidden: Boolean(!showDropDown || !inputText.trim()) }, { children: _jsxs(List, Object.assign({ className: classes.list, component: "nav" }, { children: [createSectionIsOn &&
                                createSectionText &&
                                showCreateSection ? (_jsxs(_Fragment, { children: [_jsx(ListSubheader, Object.assign({ className: classes.listHeader, component: "div", disableSticky: true }, { children: createSectionText.toUpperCase() })), _jsx(ListItem, Object.assign({ className: csn([classes.selectedRow, selectedRow === 0]), button: true, onClick: () => {
                                            if (onCreate)
                                                onCreate(inputText);
                                            setShowDropDown(false);
                                        }, tabIndex: -1 }, { children: _jsx(ListItemText, { primary: inputText }) }))] })) : null, filteredListItems] })) }))] })) })));
};
