import React from 'react';
import { logRender } from '@one-vision/utils';

import { useStyles } from './dz-company-info.styles';
import {
  CopyTooltip,
  usePartnerConfig,
  DzPartnerDetails,
  PARTNER_GROUP_RULE,
} from 'shared-ui';
import { Typography } from '@mui/material';
import { csn } from '@one-vision/utils';

interface Props {
  partnerDetails: DzPartnerDetails;
  partnerConfig: Partial<Record<PARTNER_GROUP_RULE, boolean>>;
}

export const DzCompanyInfoBox: React.FC<Props> = ({
  partnerDetails,
  partnerConfig,
}) => {
  logRender(DzCompanyInfoBox);

  const classes = useStyles();

  const { accessSubscriptionManagementPlus } = usePartnerConfig();

  const renderGridHeader = (title: string) => {
    return (
      <Typography className={classes.title} variant="h6">
        {title}
      </Typography>
    );
  };

  const renderGridLine = (title: string, value: string) => {
    return (
      <>
        <Typography className={classes.gridRowLabel} variant="body2">
          {title}
        </Typography>
        <CopyTooltip textToCopy={value} classNames={classes.gridRowValue}>
          {value}
        </CopyTooltip>
      </>
    );
  };

  return (
    <div className={csn(classes.infoBox, classes.animatedAppearance)}>
      <div className={classes.infoGridContainer}>
        <div className={classes.infoGridContainerHeader}>
          {renderGridHeader('Support Contact Details')}
        </div>
        <div className={classes.infoGridContainerBody}>
          {renderGridLine(
            'Support Email',
            partnerDetails?.supportEmail || '',
          )}
          {renderGridLine(
            'Support Phone',
            partnerDetails?.supportPhone || '',
          )}
        </div>
      </div>

      <div className={classes.infoGridContainer}>
        <div className={classes.infoGridContainerHeader}>
          {renderGridHeader('Membership Sales Details')}
        </div>
        <div className={classes.infoGridContainerBody}>
          {renderGridLine(
            'Membership Email',
            partnerDetails?.membershipEmail || '',
          )}
          {accessSubscriptionManagementPlus &&
            renderGridLine(
              'Membership Calendly',
              partnerDetails?.membershipSalesCalendlyLink || '',
            )}
          {!partnerConfig?.base_platform &&
            partnerDetails?.tos &&
            renderGridLine('Terms of Service', partnerDetails?.tos || '')}
        </div>
      </div>
    </div>
  );
};
