import { getChanges } from '@one-vision/utils';
export const addPrimary = (data) => {
    var _a, _b;
    return (Object.assign(Object.assign({}, data), { emails: ((_a = data.emails) === null || _a === void 0 ? void 0 : _a.map((el) => (Object.assign(Object.assign({}, el), { primary: el.clientEmailId === data.primaryClientEmailId })))) || [], phones: ((_b = data.phones) === null || _b === void 0 ? void 0 : _b.map((el) => (Object.assign(Object.assign({}, el), { primary: el.clientPhoneNumberId === data.primaryClientPhoneNumberId })))) || [] }));
};
export const checkEmail = (value, clientsList, originData) => {
    const busyEmails = clientsList.map((client) => client.email);
    if (value.clientEmailId) {
        const originEmail = originData.emails.find((email) => email.clientEmailId === value.clientEmailId);
        if ((originEmail === null || originEmail === void 0 ? void 0 : originEmail.email) === value.email) {
            return true;
        }
    }
    return !busyEmails.includes(value.email);
};
export const checkEditClientDialogChanges = (originalData, values) => {
    const createList = [
        ...values.emails,
        ...values.phones,
    ]
        .filter((el) => !('clientEmailId' in el || 'clientPhoneNumberId' in el))
        .map((el) => (Object.assign({ action: 'insert', value: 'email' in el ? el.email : el.phone, type: 'email' in el ? 'email' : 'phone' }, el)));
    const transformArray = (arr) => {
        return arr.reduce((acc, el) => 'clientEmailId' in el
            ? Object.assign(Object.assign({}, acc), { [el.clientEmailId]: Object.assign({}, el) }) : Object.assign(Object.assign({}, acc), { [el.clientPhoneNumberId]: Object.assign({}, el) }), {});
    };
    const originIdentities = transformArray([
        ...originalData.emails,
        ...originalData.phones,
    ]);
    const currentIdentities = transformArray([
        ...values.emails,
        ...values.phones,
    ]);
    const updateList = [];
    const deleteList = [];
    for (const identityId in originIdentities) {
        if (!currentIdentities[identityId]) {
            deleteList.push({
                type: 'clientEmailId' in originIdentities[identityId]
                    ? 'email'
                    : 'phone',
                id: identityId,
                action: 'delete',
            });
            continue;
        }
        const hasChanges = Object.keys(getChanges(originIdentities[identityId], currentIdentities[identityId])).length !== 0;
        if (!hasChanges) {
            continue;
        }
        updateList.push(Object.assign({ action: 'update', type: 'clientEmailId' in originIdentities[identityId]
                ? 'email'
                : 'phone', id: identityId, primary: currentIdentities[identityId].primary }, getChanges(originIdentities[identityId], currentIdentities[identityId])));
    }
    //remove item with only primary change from true to false
    //to avoid unnecessary request for update
    const filteredUpdateList = updateList.filter((el) => {
        if ('email' in el || 'phone' in el || 'labelId' in el) {
            return true;
        }
        return el.primary;
    });
    const modifiedUpdateList = filteredUpdateList.map((el) => {
        if ('email' in el && el.email)
            return Object.assign({ value: el.email }, el);
        if ('phone' in el && el.phone)
            return Object.assign({ value: el.phone }, el);
        return el;
    });
    const { firstName, lastName, notes } = getChanges(originalData, values);
    return {
        userData: { firstName, lastName, notes },
        identities: [...createList, ...modifiedUpdateList, ...deleteList],
    };
};
export const extractEmail = (textWithEmail) => {
    const emailReg = 
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi;
    const res = textWithEmail.match(emailReg);
    return res ? res[0] : '';
};
export const getAllEmailsIndexes = (arr, val) => {
    const indexes = [];
    let i = -1;
    while ((i = arr.indexOf(val, i + 1)) !== -1) {
        indexes.push(i);
    }
    return indexes;
};
export const findEmailIndex = (errorText, values) => {
    const emailFound = extractEmail(errorText);
    const indexes = getAllEmailsIndexes(values, emailFound);
    return [indexes, emailFound];
};
export const sortIdentities = (list) => {
    return list ? list.slice().sort((item) => (item.primary ? -1 : 1)) : [];
};
export const makeSingularEmailAndPhone = (clientData) => {
    var _a, _b;
    return Object.assign(Object.assign({}, clientData), { email: clientData.emails
            ? (_a = clientData.emails.find((el) => el.clientEmailId === clientData.primaryClientEmailId)) === null || _a === void 0 ? void 0 : _a.email
            : '', phone: clientData.phones
            ? (_b = clientData.phones.find((el) => el.clientPhoneNumberId ===
                clientData.primaryClientPhoneNumberId)) === null || _b === void 0 ? void 0 : _b.phone
            : '' });
};
export const defaultClientData = {
    ovcid: '',
    firstName: '',
    lastName: '',
    zdUserId: '',
    acUserId: '',
    pdPersonId: '',
    stripeCustomerId: '',
    doNotContact: '',
    marketingOptOut: '',
    pointOfContact: '',
    notes: '',
    createdAt: '',
    updatedAt: '',
    primaryClientEmailId: '',
    primaryClientPhoneNumberId: '',
    ovaid: '',
    zdOrgId: '',
    clientRoleId: 0,
    emails: [],
    phones: [],
    clientLabels: [],
};
