import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PeopleIcon from '@mui/icons-material/People';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { csn } from '@one-vision/utils';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DzTabs, usePartnerConfig, useUserConfig } from '../shared';
import { DzClientList } from './dz-client-list';
import { DzMembershipsView as DzMemberships } from './dz-memberships/dz-memberships.view';
import { useStyles } from './dz-org-tabs.styles';
import { DzProjects } from './dz-projects';
import { DzDocumentationView as DzDocumentation } from './dz-site-documentation/dz-site-documentation.view';
import { DzAiAssistantView } from './dz-ai-assistant/dz-ai-assistant.view';
import { DzAiIcon } from '../icons/dz-ai-icon';
/**
 * using QueryClient here as a proof of concept, when agreed to
 * use it as common approach it must be utilized for the whole project!
 * */
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5 * 60 * 1000,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            retry: 1, // Retry failed requests once
        },
    },
});
var TabNames;
(function (TabNames) {
    TabNames["USERS"] = "Users";
    TabNames["MEMBERSHIPS"] = "Memberships";
    TabNames["SITE_DOCUMENTATION"] = "Site Documentation";
    TabNames["PROJECTS"] = "Projects";
    TabNames["AI_ASSISTANT"] = "AI Assistant";
})(TabNames || (TabNames = {}));
export const DzOrgTabs = ({ address, subscriptionDetails, isLoading, clients, allClients, ovaid, projects, owners, labels, clientLabels, actions, thunks, owner, partner, dispatch, zendesk, zdUserEmail, apiClient, products, brands, checkIsAnyDialogActive, clientSyncIsLoading, clientsIsLoading, onUserCardNameClick, onProjectCardNameClick, partnerDetails, }) => {
    const { user } = useUserConfig();
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState(0);
    const [refetchClientsIsLoading, setRefetchClientsIsLoading] = useState(false);
    const handleChangeTab = useCallback((tabID) => {
        setActiveTab(tabID);
    }, [setActiveTab]);
    const refetchClients = useCallback(() => {
        if (!ovaid) {
            return;
        }
        setRefetchClientsIsLoading(true);
        dispatch(thunks.fetchClientsForAddress({
            ovaid: ovaid,
        })).finally(() => {
            setRefetchClientsIsLoading(false);
        });
    }, [dispatch, thunks, ovaid]);
    useEffect(() => {
        refetchClients();
    }, [ovaid, refetchClients]);
    const { accessToProvisionProjects, accessSubscriptionManagement } = usePartnerConfig();
    const orgDialogTabs = useMemo(() => {
        var _a;
        const tabs = [
            {
                name: TabNames.USERS,
                icon: _jsx(PeopleIcon, {}),
                content: (_jsx("div", Object.assign({ className: csn(classes.tabWrapper, classes.tabWithCardWrapper, [classes.tabWrapperZen, zendesk]) }, { children: _jsx(DzClientList, { ovaid: ovaid, primaryClientId: address === null || address === void 0 ? void 0 : address.primaryClientId, address: address, clients: clients, allClients: allClients, labels: labels, clientLabels: clientLabels, actions: actions, thunks: thunks, dispatch: dispatch, zendesk: zendesk, apiClient: apiClient, clientSyncIsLoading: clientSyncIsLoading, clientsIsLoading: clientsIsLoading || refetchClientsIsLoading, onCardNameClick: onUserCardNameClick }) }))),
            },
            {
                name: TabNames.MEMBERSHIPS,
                icon: _jsx(LocalAtmIcon, {}),
                content: (_jsx("div", Object.assign({ className: csn(classes.tabWrapper, [
                        classes.tabWrapperZen,
                        zendesk,
                    ]) }, { children: accessSubscriptionManagement ? (_jsx(DzMemberships, { apiClient: apiClient, address: address, subscriptionInfo: subscriptionDetails, isLoading: isLoading, actions: actions, thunks: thunks, owner: owner, partner: partner, dispatch: dispatch, zendesk: zendesk, zdUserEmail: zdUserEmail, checkIsAnyDialogActive: checkIsAnyDialogActive, partnerDetails: partnerDetails })) : (_jsxs(Box, Object.assign({ className: classes.noAccessSubscription }, { children: [_jsx(InfoOutlinedIcon, {}), "Partner is not using OneVision`s Subscription Management Services"] }))) }))),
            },
            {
                name: TabNames.SITE_DOCUMENTATION,
                icon: _jsx(DescriptionIcon, { "data-testid": "org-tab-documentation" }),
                content: (_jsx("div", Object.assign({ className: csn(classes.tabWrapper, [
                        classes.tabWrapperZen,
                        zendesk,
                    ]) }, { children: _jsx(DzDocumentation, { address: address, actions: actions, thunks: thunks, dispatch: dispatch, zendesk: zendesk }) }))),
            },
        ];
        if (accessToProvisionProjects) {
            tabs.push({
                name: TabNames.PROJECTS,
                icon: _jsx(PermMediaIcon, {}),
                content: (_jsx("div", Object.assign({ className: csn(classes.tabWrapper, classes.tabWithCardWrapper, [classes.tabWrapperZen, zendesk]) }, { children: _jsx(DzProjects, { projects: projects, primaryClient: clients === null || clients === void 0 ? void 0 : clients.find((el) => el.ovcid === (address === null || address === void 0 ? void 0 : address.primaryClientId)), address: address, ovaid: ovaid, owners: owners, products: products, brands: brands, thunks: thunks, actions: actions, dispatch: dispatch, labels: labels, allClients: allClients, zendesk: zendesk, apiClient: apiClient, partner: partner, onCardNameClick: onProjectCardNameClick }) }))),
            });
        }
        if (user === null || user === void 0 ? void 0 : user['ai_assistant']) {
            tabs.push({
                name: TabNames.AI_ASSISTANT,
                icon: _jsx(DzAiIcon, {}),
                content: (_jsx("div", Object.assign({ className: csn(classes.tabWrapper, classes.tabWithCardWrapper, [classes.tabWrapperZen, zendesk]) }, { children: _jsx(DzAiAssistantView, { addressId: (_a = address === null || address === void 0 ? void 0 : address.ovaid) !== null && _a !== void 0 ? _a : '', actions: actions, dispatch: dispatch }) }))),
            });
        }
        /**
         * IDs must be the same as tab order so parent component can match the content by tab index in the list
         * Ideally this logic should be refactor and content matching must be done regardless of tab position
         * */
        return tabs.map((tab, idx) => (Object.assign(Object.assign({}, tab), { id: idx })));
    }, [
        address,
        allClients,
        clients,
        clientLabels,
        projects,
        partner,
        subscriptionDetails,
        isLoading,
        clientSyncIsLoading,
        accessSubscriptionManagement,
        user,
        accessToProvisionProjects,
    ]);
    return (
    /** using QueryClientProvider here as a proof of concept, when agreed to
     * use it as common approach it must be utilized for the whole project!
     * */
    _jsx(QueryClientProvider, Object.assign({ client: queryClient }, { children: _jsx(DzTabs, { isLoading: !address, customClasses: classes, tabs: orgDialogTabs, onChange: handleChangeTab, activeTab: activeTab, zendesk: zendesk }) })));
};
