import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { FormControl, Typography, TextField, Autocomplete, } from '@mui/material';
import { logRender } from '@one-vision/utils';
import { DzDialog } from '../..';
import * as yup from 'yup';
import { withModalManager, useApiClient, } from '../../shared';
import { useStyles } from './dz-contact-support-dialog.styles';
import { useFormik } from 'formik';
import { v4 as uuid } from 'uuid';
const issueTypes = ['Question', 'Feature Request', 'Bug Report'];
const DzContactSupportDialog = ({ userName, email, dispatch, actions, isOpen, close, clear }) => {
    logRender(DzContactSupportDialog);
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const api = useApiClient();
    const validationSchema = yup.object({
        issueType: yup.string().required(),
        additionalContextText: yup.string().required(),
    });
    const formik = useFormik({
        initialValues: {
            issueType: issueTypes[0],
            additionalContextText: '',
            attachments: [],
        },
        validationSchema,
        onSubmit: async (values) => {
            var _a;
            setIsLoading(true);
            const conversationId = uuid();
            let attachmentS3Url = '';
            if (values.attachments.length) {
                const { data: { data: preSignedUrlResponse }, } = await api.generateConversationAttachmentPresignedUrl({
                    // for now, we only allow 1 attachment for support request
                    contentType: (_a = values.attachments[0]) === null || _a === void 0 ? void 0 : _a.type,
                    conversationId: conversationId,
                });
                if (preSignedUrlResponse === null || preSignedUrlResponse === void 0 ? void 0 : preSignedUrlResponse.uploadURL) {
                    await api.axios.put(preSignedUrlResponse === null || preSignedUrlResponse === void 0 ? void 0 : preSignedUrlResponse.uploadURL, values.attachments[0], {
                        headers: {
                            'Content-Type': values.attachments[0].type,
                        },
                    });
                    const urlObj = new URL(preSignedUrlResponse === null || preSignedUrlResponse === void 0 ? void 0 : preSignedUrlResponse.uploadURL);
                    attachmentS3Url = `${urlObj.origin}${urlObj.pathname}`;
                }
            }
            const result = await api.postContactSupport({
                userName: userName,
                email: email,
                issueType: values.issueType,
                additionalContextText: values.additionalContextText,
                conversationId: conversationId,
                attachments: values.attachments.map((attachment) => ({
                    file: attachment,
                    s3Url: attachmentS3Url,
                })),
            });
            if (result.status === 200) {
                dispatch(actions.updateSnackbar({
                    type: 'success',
                    text: "Your feedback has been sent, we'll be in touch shortly!",
                }));
            }
            setIsLoading(false);
            handleClose();
        },
    });
    const content = (_jsxs(FormControl, Object.assign({ fullWidth: true }, { children: [_jsx(Typography, Object.assign({ className: classes.title }, { children: "What do you need help with?" })), _jsx(Autocomplete, { value: formik.values.issueType, autoHighlight: true, openOnFocus: true, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: 'Issue Type', margin: "none" }))), options: issueTypes, onChange: (_, newValue) => {
                    if (newValue) {
                        formik.setFieldValue('issueType', newValue);
                    }
                } }), _jsx(TextField, { className: classes.additionalContextField, label: "Additional context", variant: "outlined", InputLabelProps: { shrink: true }, fullWidth: true, multiline: true, rows: 4, onChange: (event) => formik.setFieldValue('additionalContextText', event.target.value) }), _jsx(TextField, { fullWidth: true, className: classes.attachmentsField, id: "outlined-basic", label: "Attach a file", InputLabelProps: { shrink: true }, variant: "outlined", type: "file", onChange: (event) => {
                    const target = event.target;
                    const files = target.files;
                    if (files) {
                        formik.setFieldValue('attachments', [...Array.from(files)]);
                    }
                }, inputProps: {
                    multiple: false,
                } })] })));
    const handleClose = useCallback(() => {
        close();
    }, [close]);
    return (_jsx(DzDialog, { isOpen: isOpen, caption: "Need some help?", content: content, okButtonText: "Submit", cancelButtonText: "Cancel", clear: clear, onClose: handleClose, onOk: formik.submitForm, okButtonDisabled: isLoading || !formik.values.additionalContextText, isSubmittingLoader: isLoading }));
};
export default withModalManager()(DzContactSupportDialog);
