import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { actions } from 'core/redux';
import {
  Box,
  Typography,
  TextField,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  Skeleton,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {
  DzAsyncDispatch,
  DzDialog,
  DzPhoneInput,
  schemaFields,
  arraysAreEqual,
  getNumberSuffix,
  setPhoneNumberToE164,
  setToUIFormat,
} from 'shared-ui';
import { useFormik } from 'formik';
import { User } from './useUsers';
import { useStyles } from './dz-users.styles';
import { signalNotificationPreferencesList } from 'shared/constants';
import { getChanges } from '@one-vision/utils';
import {
  useUserCreateOrUpdateDialog,
  MAX_COMMUNICATIONS,
  COMMUNICATION_CHANNELS_WITH_PHONE,
} from './useUserCreateOrUpdateDialog';
import { AxiosError } from 'axios';
import useCommunicationPrefAvailable from 'shared/hooks/useCommunicationPrefAvailable';

const getCommunicationLabel = (index: number) =>
  `${index}${getNumberSuffix(index)} Notification`;

export const DzAddOrUpdateUserDialog = ({
  userToUpdate,
  onClose,
}: {
  userToUpdate: User | null;
  onClose: () => void;
}) => {
  const classes = useStyles();

  const {
    communicationLoading,
    getUserCommunication,
    updateUser,
    updateCommunicationPreferences,
    createUser,
  } = useUserCreateOrUpdateDialog();

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch<DzAsyncDispatch>();

  const isUpdate = !!Object.keys(userToUpdate || {}).length;

  const communicationPrefAvailable =
    useCommunicationPrefAvailable(userToUpdate);

  const [oldCommunication, setOldCommunication] = useState<string[]>([]);

  const oldValues = {
    firstName: userToUpdate?.firstName || '',
    lastName: userToUpdate?.lastName || '',
    phoneNumber: userToUpdate?.phoneNumber || '',
  };

  const validationSchema = yup.object({
    firstName: schemaFields.firstName,
    lastName: schemaFields.lastName,
    email: schemaFields.email,
    phoneNumber: schemaFields.phone,
    communication: yup.array().of(yup.string()),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      communication: Array(MAX_COMMUNICATIONS).fill('') as string[],
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const newValues = {
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber
            ? setPhoneNumberToE164(values.phoneNumber)
            : null,
        };

        if (isUpdate) {
          const changes = getChanges(oldValues, newValues);
          if (Object.keys(changes).length) {
            await updateUser(userToUpdate?.ownerId || '', changes);
          }
          const phoneNumberRemoved =
            'phoneNumber' in changes && !changes.phoneNumber;

          const isCommunicationUpdateRequired =
            !arraysAreEqual<string>(
              values.communication,
              oldCommunication,
            ) || phoneNumberRemoved;

          if (isCommunicationUpdateRequired) {
            await updateCommunicationPreferences(
              userToUpdate?.ownerId || '',
              values.communication,
              phoneNumberRemoved,
            );
          }
          dispatch(
            actions.updateSnackbar({
              type: 'success',
              text: 'User updated',
            }),
          );
          onClose();
          return;
        } else {
          await createUser(
            {
              ...newValues,
              email: values.email,
            },
            onClose,
            (error: string) => {
              if (error.includes('phone')) {
                formik.setFieldError('phoneNumber', error);
              }
            },
          );

          onClose();
        }
      } catch (err) {
        const error =
          (err as AxiosError).response?.data?.errors[0]?.detail ||
          'Can`t update user';
        if (error.includes('phone')) {
          formik.setFieldError('phoneNumber', error);
        }

        dispatch(
          actions.updateSnackbar({
            type: 'error',
            text: error,
          }),
        );
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (!userToUpdate) {
      return;
    }

    formik.setValues({
      firstName: userToUpdate.firstName || '',
      lastName: userToUpdate.lastName || '',
      email: userToUpdate.email || '',
      communication: [],
      phoneNumber: userToUpdate.phoneNumber
        ? setToUIFormat(userToUpdate.phoneNumber)
        : '',
    });

    if (isUpdate && communicationPrefAvailable) {
      (async () => {
        const communication = await getUserCommunication(
          userToUpdate.ownerId,
        );
        setOldCommunication(communication);
        formik.setFieldValue('communication', communication);
        setLoading(false);
      })();
    }
    // `formik` is not in dependencies because it changes after each `setValues` call
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToUpdate, getUserCommunication, communicationPrefAvailable]);

  const communicationList = signalNotificationPreferencesList.filter(
    (item) => {
      if (COMMUNICATION_CHANNELS_WITH_PHONE.includes(item.value)) {
        // The user must have phone number to be able to choose this channel
        return !!formik.values.phoneNumber;
      }

      return true;
    },
  );

  return (
    <DzDialog
      paperClass={classes.newUserDialog}
      caption={isUpdate ? 'Update user' : 'Create new user'}
      isOpen={!!userToUpdate}
      okButtonText="Submit"
      onOk={formik.handleSubmit}
      onClose={onClose}
      okButtonDisabled={loading}
      content={
        <Box display="flex" flexDirection="column">
          <Typography variant="h6">
            {isUpdate ? 'Update User' : 'Add a new user to the team'}
          </Typography>
          <AccountCircleIcon className={classes.accountIcon} />
          <TextField
            className={classes.inputMargin}
            label={'First Name'}
            error={!!formik.errors['firstName']}
            helperText={formik.errors['firstName']}
            value={formik.values.firstName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('firstName', event.target.value);
            }}
          />
          <TextField
            className={classes.inputMargin}
            label={'Last Name'}
            error={!!formik.errors['lastName']}
            helperText={formik.errors['lastName']}
            value={formik.values.lastName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('lastName', event.target.value);
            }}
          />
          <TextField
            className={classes.inputMargin}
            label={'Email'}
            error={!!formik.errors['email']}
            helperText={formik.errors['email']}
            value={formik.values.email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('email', event.target.value);
            }}
            disabled={isUpdate}
          />
          <DzPhoneInput
            label="Phone number"
            showError={!!formik.errors['phoneNumber']}
            errorText={formik.errors['phoneNumber']}
            className={classes.inputMargin}
            value={formik.values.phoneNumber}
            onChange={(value) => {
              formik.setFieldValue('phoneNumber', value);
            }}
          />
          <Divider />
          {isUpdate && communicationPrefAvailable && (
            <Box className={classes.communication}>
              <Typography className={classes.communicationHeader}>
                ProVision Signal Notification Preferences
              </Typography>
              <Typography className={classes.communicationCaption}>
                Set the order in which you would like escalation
                notifications to be sent via ProVision Signal. Email is the
                default notification if no preferences are set.
              </Typography>
              <Stack spacing={2}>
                {communicationLoading ? (
                  <>
                    <Skeleton variant="rounded" width={210} height={40} />
                    <Skeleton variant="rounded" width={210} height={40} />
                    <Skeleton variant="rounded" width={210} height={40} />
                  </>
                ) : (
                  formik.values.communication.map((el, index) => (
                    <FormControl key={`communication${index}`}>
                      <InputLabel
                        className={classes.selectLabel}
                        id={`communication${index}`}
                      >
                        {getCommunicationLabel(index + 1)}
                      </InputLabel>
                      <Select
                        className={classes.communicationSelect}
                        classes={{ select: classes.selectPadding }}
                        labelId={`communication${index}`}
                        label={getCommunicationLabel(index + 1)}
                        value={el}
                        onChange={(e) => {
                          const communication = [
                            ...formik.values.communication,
                          ];
                          communication[index] = e.target.value;
                          formik.setFieldValue(
                            'communication',
                            communication,
                          );
                        }}
                      >
                        {communicationList.map((item) => (
                          <MenuItem
                            key={`communication${index}` + item.value}
                            value={item.value}
                          >
                            {item.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ))
                )}
              </Stack>
            </Box>
          )}
        </Box>
      }
    />
  );
};
