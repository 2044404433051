import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState, } from 'react';
import { getChanges, logRender } from '@one-vision/utils';
import { Box, Divider, FormControl, FormHelperText, Typography, Autocomplete, TextField, } from '@mui/material';
import { csn } from '@one-vision/utils';
import { DzDialog, useApiClient, useGlobalStyles } from '../../shared';
import { withModalManager } from '../../shared/dz-modal-manager';
import { DzChip } from '../../shared/dz-chip';
import { useStyles } from './dz-update-tech-dialog.styles';
import { DzSkeletonUpdateTechDialog } from './dz-skeleton-update-tech-dialog.view';
import { basicSystemInformation, getSubSystemsContent, ORG_NOTES_RICH_TEXT_PLACEHOLDER, S3Client, subSystemList, } from '../../..';
import DzRichTextEditor from '../../shared/dz-rich-text-editor';
import { isValidHttpUrl } from '../../../utils';
import { replaceNewLineWithParagraph } from '../../../utils/replaceNewLineWithParagraph';
import { RsmField, RsmUrlField, } from '../../dz-address-panel/dz-site-documentation/dz-site-documentation.view';
const rsmList = [
    {
        rsmUrl: RsmUrlField.siteRsmUrl,
        rsm: RsmField.siteRemoteSystemsManagement,
    },
    {
        rsmUrl: RsmUrlField.siteSecondaryRsmUrl,
        rsm: RsmField.siteSecondaryRemoteSystemsManagement,
    },
    {
        rsmUrl: RsmUrlField.siteTertiaryRsmUrl,
        rsm: RsmField.siteTertiaryRemoteSystemsManagement,
    },
];
const checkIsRsmEmpty = (siteRsmUrl, siteRemoteSystemsManagement, addressField, address) => {
    return (addressField.includes(siteRsmUrl) &&
        !address[siteRemoteSystemsManagement]);
};
const isInputDisabled = (addressField, address) => {
    const fieldFillingList = rsmList.map((el) => checkIsRsmEmpty(el.rsmUrl, el.rsm, addressField, address));
    return fieldFillingList.includes(true);
};
function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}
const fieldErrorText = 'Field is required';
const fieldErrorUrl = 'URL is not valid';
export const DzUpdateTechDialog = ({ actions, thunks, dispatch, isOpen, close, ovaid, zendesk = false, }) => {
    logRender(DzUpdateTechDialog);
    const classes = Object.assign(Object.assign({}, useGlobalStyles()), useStyles());
    const [submitting, setIsSubmitting] = useState(false);
    const [subSystems, setSubSystems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [address, setAddress] = useState({ ovaid });
    const [originalAddress, setOriginalAddress] = useState({});
    const updateAddress = (changes) => {
        setAddress((state) => (Object.assign(Object.assign({}, state), changes)));
    };
    const hasError = () => {
        const filtered = Object.values(errors).filter((value) => typeof value === 'string');
        return Boolean(filtered.length);
    };
    useEffect(() => {
        if (ovaid && isOpen) {
            setIsLoading((prev) => !prev);
            const fetching = async () => {
                setAddress({ ovaid });
                dispatch(thunks.fetchUpdateTechDialogData(ovaid))
                    .unwrap()
                    .then((addresses) => {
                    if (addresses.length) {
                        setAddress(Object.assign({}, addresses[0]));
                        setOriginalAddress(Object.assign({}, addresses[0]));
                    }
                    setIsLoading((prev) => !prev);
                });
            };
            fetching();
        }
    }, [ovaid, isOpen]);
    useEffect(() => {
        const withValues = subSystemList.reduce((accumulator, currentValue) => {
            const hasValue = currentValue.addresses.find((item) => address[item]);
            if (hasValue)
                accumulator.push(currentValue.label);
            return accumulator;
        }, []);
        setSubSystems(withValues);
    }, [originalAddress]);
    const handleClose = useCallback(() => {
        close();
        setAddress({});
        setOriginalAddress({});
    }, [close, setAddress, setOriginalAddress]);
    const handleChipClick = useCallback((newVal, addresses) => {
        const checked = subSystems.includes(newVal);
        if (checked) {
            setSubSystems((prev) => prev.filter((val) => val !== newVal));
            const changedValues = {};
            addresses.forEach((item) => {
                Object.assign(changedValues, {
                    [item]: null,
                });
            });
            setAddress((prev) => (Object.assign(Object.assign({}, prev), changedValues)));
        }
        else {
            setSubSystems((prev) => [...prev, newVal].filter(onlyUnique));
        }
    }, [subSystems, setAddress]);
    const validate = useCallback(() => {
        const validations = {};
        basicSystemInformation.forEach(({ required, addressField }) => {
            if (required) {
                const value = address[addressField];
                if (!value) {
                    Object.assign(validations, { [addressField]: fieldErrorText });
                }
            }
        });
        const siteRsmUrlList = [
            { value: address.siteRsmUrl, name: RsmUrlField.siteRsmUrl },
            {
                value: address.siteSecondaryRsmUrl,
                name: RsmUrlField.siteSecondaryRsmUrl,
            },
            {
                value: address.siteTertiaryRsmUrl,
                name: RsmUrlField.siteTertiaryRsmUrl,
            },
        ];
        siteRsmUrlList.forEach((el) => {
            if (el.value && !isValidHttpUrl(el.value)) {
                Object.assign(validations, { [el.name]: fieldErrorUrl });
            }
        });
        setErrors(validations);
        return !Object.keys(validations).length;
    }, [address, fieldErrorUrl, setErrors]);
    const handleSubmit = useCallback(async () => {
        setIsSubmitting(true);
        const addressChanges = getChanges(originalAddress, address, 'ovaid');
        const isValid = validate();
        if (isValid) {
            setIsLoading((prev) => !prev);
            const addressUpdates = new Promise((resolveAddress) => {
                if (Object.keys(addressChanges).length) {
                    addressChanges.ovaid = address.ovaid;
                    dispatch(thunks.updateAddress({
                        ovaid: address.ovaid,
                        changes: addressChanges,
                    }))
                        .unwrap()
                        .then(() => {
                        resolveAddress(null);
                    });
                }
                else {
                    resolveAddress(null);
                }
            });
            addressUpdates.then(() => {
                dispatch(actions.updateSnackbar({
                    text: 'Changes were successfully submitted!',
                    type: 'success',
                }));
                setIsLoading((prev) => !prev);
                handleClose();
            });
        }
        setIsSubmitting(false);
    }, [
        address,
        originalAddress,
        dispatch,
        handleClose,
        setIsSubmitting,
        validate,
    ]);
    const errorsHandler = (addressField) => {
        setErrors((prev) => (Object.assign(Object.assign({}, prev), { [addressField]: null })));
    };
    const onAutocompleteValueChange = (newValue, addressField) => {
        rsmList.forEach((el) => {
            if (addressField === el.rsm && !newValue) {
                updateAddress({
                    [el.rsmUrl]: null,
                });
            }
        });
        updateAddress({
            [addressField]: (newValue === null || newValue === void 0 ? void 0 : newValue.key) || null,
        });
    };
    const getFormField = ({ caption, addressField, items: initialItems, required, subHeader, }) => {
        const error = required && errors[addressField];
        const needAddCurrentValue = address[addressField] &&
            !initialItems.find((item) => item.key === address[addressField]);
        const onInputChange = (event) => {
            setErrors((prev) => (Object.assign(Object.assign({}, prev), { [addressField]: null })));
            updateAddress({
                [addressField]: event.target.value,
            });
        };
        if (!initialItems.length) {
            return (_jsxs(_Fragment, { children: [_jsx(TextField, { className: classes.gcFieldMargin, label: caption, value: address[addressField], onChange: onInputChange, error: !!errors[addressField], disabled: isInputDisabled(addressField, address) }), !!errors[addressField] && (_jsx(FormHelperText, { children: fieldErrorUrl }))] }));
        }
        const items = !needAddCurrentValue
            ? initialItems
            : [
                ...initialItems,
                {
                    name: address[addressField],
                    key: address[addressField],
                },
            ];
        return [
            subHeader ? (_jsx(Typography, Object.assign({ className: classes.subTitle }, { children: subHeader }), `subheader-${subHeader}`)) : null,
            _jsxs(FormControl, Object.assign({ className: classes.gcFieldMargin, error: Boolean(error) }, { children: [_jsx(Autocomplete, { autoHighlight: true, openOnFocus: true, value: items.find((el) => el.key === address[addressField]) || null, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: caption, margin: "none" }))), options: items, getOptionLabel: (option) => option.name, isOptionEqualToValue: (option) => option.key === address[addressField], onChange: (_, newValue) => {
                            errorsHandler(addressField);
                            onAutocompleteValueChange(newValue, addressField);
                        } }), error && _jsx(FormHelperText, { children: fieldErrorText })] }), addressField),
        ];
    };
    const getBasicSystemInformationContent = () => {
        return basicSystemInformation.map((item) => getFormField(item));
    };
    const disableSaveChanges = () => {
        return !Object.keys(getChanges(originalAddress, address, 'ovaid'))
            .length;
    };
    const apiClient = useApiClient();
    const s3Client = useMemo(() => {
        return new S3Client({ apiClient });
    }, [apiClient]);
    const content = () => {
        return (_jsx(Box, Object.assign({ display: "flex", flexDirection: "column", "data-testid": "container" }, { children: isLoading ? (_jsx(DzSkeletonUpdateTechDialog, { zendesk: zendesk })) : (_jsxs(_Fragment, { children: [_jsx(Typography, Object.assign({ className: csn(classes.title, [classes.titleZen, zendesk]) }, { children: "Org Notes" })), _jsx(DzRichTextEditor, { placeholder: ORG_NOTES_RICH_TEXT_PLACEHOLDER, content: replaceNewLineWithParagraph(address.orgNotes || ''), onUpdate: ({ editor }) => {
                            const value = editor.getHTML();
                            updateAddress({ orgNotes: value });
                        }, s3Client: s3Client }), _jsx(Divider, { className: csn(classes.divider, [
                            classes.dividerZen,
                            zendesk,
                        ]) }), _jsx(Typography, Object.assign({ className: csn(classes.title, [classes.titleZen, zendesk]) }, { children: "System Infrastructure" })), getBasicSystemInformationContent(), _jsx(Divider, { className: csn(classes.divider, [
                            classes.dividerZen,
                            zendesk,
                        ]) }), _jsx(Typography, Object.assign({ className: csn(classes.title, [classes.titleZen, zendesk]) }, { children: "Choose Sub Systems" })), _jsx("div", Object.assign({ className: classes.chipContainer }, { children: subSystemList.map(({ label, addresses }) => {
                            return (_jsx(DzChip, { "data-testid": `chip-${label
                                    .trim()
                                    .toLocaleLowerCase()}`, className: classes.chip, label: label, checked: subSystems.includes(label), onClick: (value) => handleChipClick(value, addresses) }, label));
                        }) })), subSystems.map((subSystem) => getSubSystemsContent(subSystem, getFormField)), _jsx(Divider, { className: csn(classes.divider, [
                            classes.dividerZen,
                            zendesk,
                        ]) })] })) })));
    };
    return (_jsx(DzDialog, { isOpen: isOpen, caption: "Update Site Documentation", okButtonText: "Save", okButtonDisabled: disableSaveChanges() || submitting || hasError(), content: content(), onClose: handleClose, onOk: handleSubmit, zendesk: zendesk }));
};
export default withModalManager()(DzUpdateTechDialog);
