import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useCallback } from 'react';
import { LocationOnOutlined } from '@mui/icons-material';
import usePlacesAutocomplete, { getGeocode, } from 'use-places-autocomplete';
import { TextField, Autocomplete, Grid, Typography, Box, } from '@mui/material';
import { GoogleTypes } from '../../../types';
const getAddress1 = (value) => {
    const [address1] = value.split(',');
    return address1;
};
const buildPlaceObject = (place) => {
    const object = {
        address1: getAddress1(place.formatted_address),
    };
    for (const component of place.address_components) {
        component.types.forEach((type) => {
            switch (type) {
                case GoogleTypes.SublocalityLevel1:
                    object.city = component.long_name;
                    break;
                case GoogleTypes.Locality:
                    if (!object.city) {
                        object.city = component.long_name;
                    }
                    break;
                case GoogleTypes.AdministrativeAreaLevel1:
                    if (!object.state) {
                        object.state = component.short_name.toUpperCase();
                    }
                    break;
                case GoogleTypes.PostalCode:
                    if (!object.zip) {
                        object.zip = component.long_name;
                    }
                    break;
                default:
                    break;
            }
        });
    }
    return object;
};
export const DzGooglePlacesAutocompleteView = ({ onOptionSelected, onInputValueChanged, label, id, onBlur, initialValue, }) => {
    const { ready, setValue, suggestions: { data }, clearSuggestions, value, } = usePlacesAutocomplete({
        cache: false,
        requestOptions: {
            componentRestrictions: { country: ['us', 'ca'] },
        },
    });
    const options = useMemo(() => data.map((el) => el.description), [data]);
    const getOptionLabel = useCallback((option) => option, []);
    const onInputChange = useCallback((_, newValue) => {
        const address1 = getAddress1(newValue);
        setValue(address1);
        if (onInputValueChanged) {
            onInputValueChanged(address1);
        }
    }, [onInputValueChanged]);
    const onChange = useCallback(async (_, newValue) => {
        const address1 = getAddress1(newValue || '');
        setValue(address1, false);
        clearSuggestions();
        if (onOptionSelected) {
            const results = await getGeocode({
                address: newValue,
            });
            onOptionSelected(buildPlaceObject(results[0]));
        }
    }, [onOptionSelected]);
    const renderInput = useCallback((params) => {
        return (_jsx(TextField, Object.assign({}, params, { autoFocus: true, label: label, fullWidth: true, onKeyDown: (e) => {
                if (e.code === 'enter') {
                    const address1 = getAddress1(value);
                    setValue(address1);
                }
            } })));
    }, [value]);
    const renderOption = useCallback((props, option) => {
        return (_jsx("li", Object.assign({}, props, { children: _jsxs(Grid, Object.assign({ container: true, alignItems: "center" }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsx(Box, { component: LocationOnOutlined, sx: { color: 'text.secondary', mr: 2 } }) })), _jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(Typography, Object.assign({ variant: "body2", color: "text.secondary" }, { children: option })) }))] })) })));
    }, []);
    React.useEffect(() => {
        if (ready && initialValue && !value) {
            setValue(initialValue);
        }
    }, [ready, initialValue]);
    return (_jsx(Autocomplete, { "data-testid": "google-places-autocomplete", id: id, value: value, options: options, getOptionLabel: getOptionLabel, freeSolo: true, onBlur: onBlur, onInputChange: onInputChange, onChange: onChange, renderInput: renderInput, 
        // @ts-expect-error TODO: Figure out renderOption typings problem.
        renderOption: renderOption }));
};
