import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => ({
    editorWrapper: {
        border: '1px solid #9E9E9E',
        borderRadius: '4px 4px 0 0',
    },
    editorContent: {
        '& > *': {
            padding: `0 ${theme.spacing(2)}`,
            overflow: 'auto',
        },
    },
    proseMirror: {
        minHeight: '8rem',
    },
    editorMenu: {
        padding: theme.spacing(1),
        display: 'flex',
        columnGap: theme.spacing(2),
        borderBottom: '1px solid #9E9E9E',
        flexWrap: 'wrap',
    },
    menuButton: {
        padding: 0,
        height: '24px',
        minWidth: '24px',
    },
    richTaskList: {
        listStyle: 'none',
        padding: 0,
        '& p': {
            margin: 0,
        },
    },
    richTaskItem: {
        display: 'flex',
        '& > label': {
            flex: '0 0 auto',
            marginRight: '0.5rem',
            userSelect: 'none',
        },
        '& > div': {
            flex: '1 1 auto',
        },
    },
    richLink: {
        cursor: 'pointer',
    },
    codeBlock: {
        background: '#0d0d0d',
        borderRadius: '0.5rem',
        color: '#fff',
        fontFamily: '"JetBrainsMono", monospace',
        padding: '0.75rem 1rem',
        '& code': {
            background: 'none',
            color: 'inherit',
            fontSize: '0.8rem',
            padding: 0,
        },
    },
    blockQuote: {
        borderLeft: '3px solid rgba(13, 13, 13, 0.1)',
        paddingLeft: '1rem',
    },
    richImage: {
        height: 'auto',
        maxWidth: '100%',
        '&.ProseMirror-selectednode': {
            outline: '3px solid #68cef8',
        },
        '&:first-child': {
            marginTop: '1rem',
        },
    },
    emptyEditor: {
        '&::before': {
            color: '#adb5bd',
            content: ({ placeholder }) => `"${placeholder}"`,
            float: 'left',
            height: 0,
            pointerEvents: 'none',
        },
    },
}));
