import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo, useRef, useState } from 'react';
import { IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography, } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { csn } from '@one-vision/utils';
import { useStyles } from './dz-side-panel-header.styles';
import { DzCircularProgress, usePartnerConfig } from '..';
import { Box } from '@mui/material';
import { DzAutocompletePopper } from '../dz-autocomplete-popper';
import { DzAfURLCopyModal } from '..';
import { DzCardHeaderName } from '../dz-card-header-name';
export const DzSidePanelHeaderView = ({ icons, title, isLoading, menuItems, customClasses, menuIsOpen, switchMenu, zendesk, clients, apiClient, address, onCardNameClick, }) => {
    const classes = Object.assign(Object.assign({}, useStyles()), customClasses);
    const refMenu = useRef(null);
    const [anchorElement, setAnchor] = useState(null);
    const autocompleteRef = useRef(null);
    const memoizedClients = useMemo(() => clients || [], [clients]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [client, setClient] = useState(null);
    const { accessSubscriptionManagement } = usePartnerConfig();
    const closeModalHandler = useCallback(() => {
        setModalIsOpen(false);
        setClient(null);
    }, [setModalIsOpen, setClient]);
    const getEmail = (client) => {
        const primaryClientEmailId = client.primaryClientEmailId;
        return client.emails.find((el) => el.clientEmailId === primaryClientEmailId);
    };
    const handleSwitchMenu = useCallback(() => {
        switchMenu(!menuIsOpen);
    }, [menuIsOpen]);
    const openAutocomplete = useCallback((event) => {
        setAnchor(event.currentTarget);
    }, [setAnchor]);
    const closeAutocomplete = useCallback(() => {
        if (anchorElement) {
            anchorElement.focus();
        }
        setAnchor(null);
    }, [anchorElement, setAnchor]);
    const handleOpenAfURLModal = useCallback(async (option) => {
        if (option) {
            setClient(option);
            setModalIsOpen(true);
        }
    }, [address, setModalIsOpen]);
    const onCardNameClickHandler = useCallback(() => onCardNameClick && onCardNameClick(address.ovaid), [onCardNameClick, address.ovaid]);
    return (_jsxs(Box, Object.assign({ display: "flex", alignItems: "center", justifyContent: "space-between" }, { children: [_jsxs("div", Object.assign({ className: classes.headerTitleWrap }, { children: [_jsx("div", Object.assign({ className: classes.icons }, { children: icons })), _jsx("div", Object.assign({ className: csn(classes.title, [classes.titleZen, zendesk]) }, { children: _jsx(DzCardHeaderName, { copyText: title, openText: 'Open organization', onClick: onCardNameClickHandler, hideTooltip: !onCardNameClick || zendesk, zendesk: zendesk }) }))] })), _jsxs("div", Object.assign({ className: csn(classes.headerActions, [
                    classes.headerActionsZen,
                    zendesk,
                ]) }, { children: [accessSubscriptionManagement && Boolean(clients.length) && (_jsx(Tooltip, Object.assign({ title: _jsxs(_Fragment, { children: [_jsx(Typography, Object.assign({ align: "center", variant: "subtitle2", classes: { subtitle2: classes.tooltipFirstLine } }, { children: "Generate Client Activation URL" })), _jsxs(Typography, Object.assign({ align: "center", variant: "subtitle2", classes: { subtitle2: classes.tooltipSecondLine } }, { children: ["Click and select a user to ", _jsx("br", {}), " generate a unique URL."] }))] }) }, { children: _jsx(IconButton, Object.assign({ onClick: openAutocomplete, ref: autocompleteRef, size: "small", disabled: !clients }, { children: _jsx(ShareIcon, {}) })) }))), _jsx(DzAutocompletePopper, { isOpen: Boolean(anchorElement), anchorEl: autocompleteRef, closeAutocomplete: closeAutocomplete, label: "Choose a link to copy", placeholder: "Select client to copy URL", options: memoizedClients, onChange: handleOpenAfURLModal, getOptionLabel: (option) => `${option.firstName} ${option.lastName}`, getOptionDisabled: (option) => { var _a; return !((_a = getEmail(option)) === null || _a === void 0 ? void 0 : _a.email); }, renderOption: (props, option) => {
                            var _a;
                            return (_jsxs("li", Object.assign({}, props, { children: [_jsx(LinkIcon, { className: classes.rotateUrlIcon }), _jsxs("div", Object.assign({ className: classes.dropdownSpacing }, { children: [_jsx("div", { children: _jsx("div", Object.assign({ className: classes.ellipses }, { children: `${option.firstName} ${option.lastName}` })) }), _jsx("div", Object.assign({ className: classes.dropdownSecondaryText }, { children: _jsx("div", Object.assign({ className: classes.ellipses }, { children: ((_a = getEmail(option)) === null || _a === void 0 ? void 0 : _a.email) ||
                                                        'Unavailable. No email address.' })) }))] }))] })));
                        } }), _jsx(IconButton, Object.assign({ size: "small", ref: refMenu, onClick: handleSwitchMenu }, { children: _jsx(MoreVertIcon, { "data-testid": "activate-header-menu-list-user-side-panel" }) })), isLoading ? (_jsx(DzCircularProgress, { className: classes.circularProgress })) : (_jsx(Menu, Object.assign({ open: menuIsOpen, anchorEl: refMenu.current, onClose: handleSwitchMenu }, { children: menuItems.map((el) => {
                            return (_jsx(Tooltip, Object.assign({ title: el.tooltip }, { children: _jsx(Box, { children: _jsxs(MenuItem
                                    // If action is always provided, it is possible to
                                    // bypass the `disabled` attribute by enabling pointer-events in devtools.
                                    , Object.assign({ 
                                        // If action is always provided, it is possible to
                                        // bypass the `disabled` attribute by enabling pointer-events in devtools.
                                        onClick: el.disabled ? undefined : el.action, disabled: el.disabled }, { children: [_jsx(ListItemIcon, Object.assign({ className: classes.listItemIcon }, { children: el.icon })), _jsx(Typography, Object.assign({ variant: "inherit" }, { children: el.name }))] })) }) }), el.name));
                        }) })))] })), _jsx(DzAfURLCopyModal, { isOpen: modalIsOpen, onClose: closeModalHandler, address: address, client: client, apiClient: apiClient, zendesk: zendesk })] })));
};
